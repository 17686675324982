import { dateTimeToUnixTimestamp, getPosNote } from '@/libs/receiptIssuing/commons'
import { clone } from '@/plugins/utils'

export const getPayload = async (data, items, args, state, receiptNote = null) => {
  if (args.entity === 'offer' && state.getCashRegister().type === 'ALL') {
    var newItems = []
    data.totalWithDiscount = 0
    items.forEach(item => {
      var wholesaleIt = getWholesaleItem(item)
      newItems.push(wholesaleIt)
      data.totalWithDiscount += wholesaleIt.price
    })

    items = newItems
  }
  const posNote = await getPosNote(state)

  const payload = {
    action: {
      operation: 'set',
      entity: args.entity,
      params: {
        company_id: state.getCurrentCompany().id,
        location_id: state.getPointOfSale().id,
        register_id: state.getCashRegister().id,
        issue_date_time: dateTimeToUnixTimestamp(new Date()),
        payment_method: args.paymentMethod,
        seller_oib: '',
        currency: data.currency,
        payment_reference: args.paymentReference ?? '',
        language: args.language.value,
        type: args.type,
        items: items,
        note: posNote
      }
    }
  }

  if (receiptNote && receiptNote.note) {
    payload.action.params.note = `${receiptNote.note}\r\n${posNote}`
  }

  payload.action.params.delivery_date = undefined
  payload.action.params.payment_due = undefined
  delete payload.action.params.itemID

  if (data.discount !== undefined) {
    payload.action.params.discount = data.discount
  }

  if (data.discount !== undefined) {
    payload.action.params.discount = data.discount
  }

  if (data.fullTotal !== undefined && data.fullTotal > 0) {
    payload.action.params.full_total = data.fullTotal
  } else {
    payload.action.params.full_total = data.total
  }

  if (data.total !== undefined && data.total > 0) {
    payload.action.params.total = data.total
  }

  if (data.totalWithDiscount !== undefined && data.totalWithDiscount > 0) {
    payload.action.params.total = data.totalWithDiscount
  }

  payload.action.params.number = parseInt(args.receiptNumber)
  payload.action.params.fiscalize = args.fiscalize
  if (['T58', 'T80', 'A4'].includes(state.getPrinter().value)) {
    payload.action.params.print_size = state.getPrinter().value
  } else {
    payload.action.params.print_size = 'BIXOLON80'
  }
  return payload
}

export const getVirmanPayload = async (data, items, args, state, receiptNote = null) => {
  if (state.getCashRegister().type === 'MP' || state.getCashRegister().type === 'ALL') {
    var newItems = []
    data.totalWithDiscount = 0
    items.forEach(item => {
      var wholesaleIt = getWholesaleItem(item)
      newItems.push(wholesaleIt)
      data.totalWithDiscount += wholesaleIt.price
    })

    items = newItems
  }

  const posNote = await getPosNote(state)
  args.type.flag = 'V'
  const payload = {
    action: {
      operation: 'set',
      entity: args.entity,
      params: {
        company_id: state.getCurrentCompany().id,
        location_id: state.getPointOfSale().id,
        register_id: state.getCashRegister().id,
        issue_date_time: dateTimeToUnixTimestamp(new Date()),
        payment_method: 'VIRMAN',
        currency: data.currency,
        payment_reference: '',
        language: args.language.value,
        type: args.type,
        items: items,
        note: posNote
      }
    }
  }

  if (receiptNote && receiptNote.note) {
    payload.action.params.note = `${receiptNote.note}\r\n${posNote}`
  }

  payload.action.params.delivery_date = undefined
  payload.action.params.payment_due = undefined
  delete payload.action.params.itemID

  if (data.discount !== undefined) {
    payload.action.params.discount = data.discount
  }

  if (data.fullTotal !== undefined && data.fullTotal > 0) {
    payload.action.params.full_total = data.fullTotal
  } else {
    payload.action.params.full_total = data.total
  }

  if (data.total !== undefined && data.total > 0) {
    payload.action.params.total = data.total
  }

  if (data.totalWithDiscount !== undefined && data.totalWithDiscount > 0) {
    payload.action.params.total = data.totalWithDiscount
  }

  payload.action.params.number = parseInt(args.receiptNumber)
  if (['T58', 'T80', 'A4'].includes(state.getPrinter().value)) {
    payload.action.params.print_size = state.getPrinter().value
  } else {
    payload.action.params.print_size = 'BIXOLON80'
  }

  return payload
}

export const getWholesaleItem = (item) => {
  const newItem = clone(item)
  var pnpAmt = 0
  if (newItem.pnp && newItem.pnp !== null && newItem.pnp.rate && newItem.pnp.rate > 0) {
    pnpAmt = newItem.pnp.rate
  }

  var taxes = newItem.pdv.rate + pnpAmt

  newItem.price =
    Math.round(parseFloat(newItem.price / (1 + taxes / 10000)), 2)

  newItem.single_item_price =
    Math.round(parseFloat(newItem.single_item_price / (1 + taxes / 10000)), 2)

  return newItem
}
