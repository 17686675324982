<template>
  <div>
    <v-dialog :max-width="width"
      v-model="dialog"
      content-class="my-custom-dialog"
      persistent
      @keydown.esc="close">
      <v-card tile
        color="white">
        <v-card-title>
          <h2 class="text--secondary">
            Odaberite način plaćanja
          </h2>
          <v-spacer></v-spacer>
          <v-icon large
            @click="close">mdi-close</v-icon>
        </v-card-title>
      </v-card>
      <v-card-text class="white">
        <v-container>
          <v-row class="panel-row">
            <!-- SVE OSTALO -->
            <v-col v-for="(menu, idxc) in paymentMenu"
              :key="idxc"
              :cols="12"
              class="mb-1 pb-0 mt-0 pt-0">
              <v-btn block
                tile
                style="border-radius: 14px;"
                depressed
                min-height="6rem"
                color="#295586"
                class="mb-0 pb-0"
                return-object
                @click="menu.type !== 'K' ? menuPaymentClick(menu) : checkCards()">
                <span class="white--text tile btn-lg text-xs-center">{{ menu.text }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialog: false,
      paymentOption: { name: '', value: '' },
      width: '500px'
    }
  },
  props: {
    paymentMenu: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    open () {
      this.dialog = true
    },
    close () {
      this.dialog = false
    },
    checkCards () {
      this.$emit('checkCards')
      this.close()
    },
    menuPaymentClick (menu) {
      this.paymentOption = menu
      this.$emit('menuPaymentClick', menu)
      this.close()
    }
  }
}
</script>

<style scoped>
@media only screen and (max-height: 899px) {
  #container {
    max-height: 193px;
  }

  span.tile {
    display: block;
    white-space: normal;
  }
}

@media only screen and (min-height: 900px) {
  #container {
    max-height: 440px;
  }

  span.tile {
    display: block;
    white-space: normal;
  }
}

.btn-lg {
  font-size: 1.5rem;
  font-weight: 700;
}
</style>
