<template>
  <div>
    <v-container max-height="100px">
      <panels-toolbar-simple :items="data.items"
        :receiptNote="receiptNote"
        @openSearchItemsDialog="openSearchItemsDialog"
        @openCloseBalanceDialog="openCloseBalanceDialog"
        @openReceiptsDialog="openReceiptsDialog"
        @finishPaymentRep="confirmReprezentacija()"
        @deliveryFinishSimplePayment="deliveryFinishSimplePayment"></panels-toolbar-simple><!------------------------------------------------------------------------- GOTOVINSKI -->
      <v-row v-if="cashRegisterType !== 'VP'">
        <v-col cols="4"
          style="padding: 0 2px 2px 0">
          <v-tooltip bottom
            :open-delay="tooltipDelay"
            :open-on-hover="true"
            :open-on-click="false"
            :open-on-focus="false">
            <template #activator="{ on }">
              <div v-on="on">
                <v-btn style="border-radius: 12px"
                  block
                  tile
                  depressed
                  min-height="3.5rem"
                  color="#2C5A90"
                  @click="finishPayment('G', 'BO')"
                  :disabled="!buttonsEnabled || cashRegisterType === 'VP'">
                  <span class="white--text tile btn-lg">{{
                    $t("$vuetify.paymentMethods.cash")
                  }}</span>
                </v-btn>
              </div>
            </template>
            <span>{{ $t("$vuetify.tooltips.payWithCash") }}</span>
          </v-tooltip>
        </v-col>
        <!------------------------------------------------------------------------- KARTIČNO -->
        <v-col cols="4"
          style="padding: 0 2px 2px 0">
          <v-menu bottom
            right>
            <template v-slot:activator="{ on: onMenu, attrs }">
              <v-tooltip bottom
                :open-delay="tooltipDelay"
                :open-on-hover="true"
                :open-on-click="false"
                :open-on-focus="false">
                <template #activator="{ on: onTooltip }">
                  <div v-on="{ ...onMenu, ...onTooltip }">
                    <v-btn style="border-radius: 12px"
                      block
                      tile
                      depressed
                      min-height="3.5rem"
                      icon
                      :class="[
                        {
                          'disable-events':
                            !buttonsEnabled || cashRegisterType === 'VP',
                        },
                        'white--text',
                        {
                          darkblue: buttonsEnabled && cashRegisterType !== 'VP',
                        },
                      ]"
                      v-bind="attrs"
                      @click="checkCards()">
                      <span class="white--text tile btn-lg">{{
                        $t("$vuetify.paymentMethods.card")
                      }}</span>
                    </v-btn>
                  </div>
                </template>
                <span>{{ $t("$vuetify.tooltips.payWithCards") }}</span>
              </v-tooltip>
            </template>
          </v-menu>
        </v-col>
        <!------------------------------------------------------------------------- R1 -->
        <v-col cols="4"
          style="padding: 0 0 2px 0">
          <v-menu bottom
            right>
            <template v-slot:activator="{ on: onMenu, attrs }">
              <v-tooltip bottom
                :open-delay="tooltipDelay"
                :open-on-hover="true"
                :open-on-click="false"
                :open-on-focus="false">
                <template #activator="{ on: onTooltip }">
                  <div v-on="{ ...onMenu, ...onTooltip }">
                    <v-btn style="border-radius: 12px"
                      block
                      tile
                      depressed
                      min-height="3.5rem"
                      icon
                      :class="[
                        { 'disable-events': !buttonsEnabled },
                        'white--text',
                        { darkblue: buttonsEnabled },
                      ]"
                      v-bind="attrs"
                      @click="openR1ReceiptPaymentDialog">
                      <span class="white--text tile btn-lg">R1</span>
                    </v-btn>
                  </div>
                </template>
                <span>{{ $t("$vuetify.tooltips.payR1") }}</span>
              </v-tooltip>
            </template>
          </v-menu>
        </v-col>
      </v-row>
      <!------------------------------------------------------------------------- RAČUN (SAMO VP) -->
      <v-row v-if="cashRegisterType === 'VP'">
        <v-col cols="12"
          style="padding: 0 0 2px 0">
          <v-menu bottom
            right>
            <template v-slot:activator="{ on: onMenu, attrs }">
              <v-tooltip bottom
                :open-delay="tooltipDelay"
                :open-on-hover="true"
                :open-on-click="false"
                :open-on-focus="false">
                <template #activator="{ on: onTooltip }">
                  <div v-on="{ ...onMenu, ...onTooltip }">
                    <v-btn block
                      tile
                      depressed
                      min-height="3.5rem"
                      icon
                      :class="[
                        { 'disable-events': !buttonsEnabled },
                        'white--text',
                        { darkblue: buttonsEnabled },
                      ]"
                      v-bind="attrs"
                      @click="openWsPaymentDialog">
                      <span class="white--text tile btn-lg">Račun</span>
                    </v-btn>
                  </div>
                </template>
                <span>Račun</span>
              </v-tooltip>
            </template>
          </v-menu>
        </v-col>
      </v-row>
      <!------------------------------------------------------------------------- END SELECTING -->
      <!-- <v-row v-if="cashRegisterType !== 'VP'">
        <v-col cols="4" style="padding: 0 2px 2px 0">
          <v-tooltip
            bottom
            :open-delay="tooltipDelay"
            :open-on-hover="true"
            :open-on-click="false"
            :open-on-focus="false"
          >
            <template #activator="{ on }">
              <div v-on="on">
                <v-btn
                  :disabled="!buttonsEnabled || cashRegisterType === 'VP'"
                  block
                  tile
                  depressed
                  min-height="3.5rem"
                  color="#2C5A90"
                  @click="finishPayment('G', 'BO')"
                >
                  <span class="white--text tile btn-lg">{{
                    $t("$vuetify.paymentMethods.cash")
                  }}</span>
                </v-btn>
              </div>
            </template>
            <span>{{ $t("$vuetify.tooltips.payWithCash") }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="4" style="padding: 0 2px 2px 0">
          <v-menu bottom right>
            <template v-slot:activator="{ on: onMenu, attrs }">
              <v-tooltip
                bottom
                :open-delay="tooltipDelay"
                :open-on-hover="true"
                :open-on-click="false"
                :open-on-focus="false"
              >
                <template #activator="{ on: onTooltip }">
                  <div v-on="{ ...onMenu, ...onTooltip }">
                    <v-btn
                      block
                      tile
                      depressed
                      min-height="3.5rem"
                      icon
                      :class="[
                        {
                          'disable-events':
                            !buttonsEnabled || cashRegisterType === 'VP',
                        },
                        'white--text',
                        {
                          darkblue: buttonsEnabled && cashRegisterType !== 'VP',
                        },
                      ]"
                      v-bind="attrs"
                      @click="checkCards()"
                    >
                      <span class="white--text tile btn-lg">{{
                        $t("$vuetify.paymentMethods.card")
                      }}</span>
                    </v-btn>
                  </div>
                </template>
                <span>{{ $t("$vuetify.tooltips.payWithCards") }}</span>
              </v-tooltip>
            </template>
            <v-list
              v-if="
                availableCards && availableCards.length > 0 && buttonsEnabled
              "
            >
              <v-list-item
                v-for="(card, idx) in availableCards"
                :key="idx"
                @click="checkTerminal('K', 'BO', undefined, card)"
              >
                <v-list-item-title>{{ card.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="4" style="padding: 0 0 2px 0">
          <v-menu bottom right>
            <template v-slot:activator="{ on: onMenu, attrs }">
              <v-tooltip
                bottom
                :open-delay="tooltipDelay"
                :open-on-hover="true"
                :open-on-click="false"
                :open-on-focus="false"
              >
                <template #activator="{ on: onTooltip }">
                  <div v-on="{ ...onMenu, ...onTooltip }">
                    <v-btn
                      block
                      tile
                      depressed
                      min-height="3.5rem"
                      icon
                      :class="[
                        { 'disable-events': !buttonsEnabled },
                        'white--text',
                        { darkblue: buttonsEnabled },
                      ]"
                      v-bind="attrs"
                    >
                      <span class="white--text tile btn-lg">R1</span>
                    </v-btn>
                  </div>
                </template>
                <span>{{ $t("$vuetify.tooltips.payR1") }}</span>
              </v-tooltip>
            </template>
            <v-list v-if="buttonsEnabled">
              <v-list-item
                v-for="(menu, idx) in paymentMenu"
                :key="idx"
                @click="
                  menu.type !== 'K' ? menuPaymentClick(menu) : checkCardsForR()
                "
              >
                <v-list-item-title
                  v-if="
                    menu.type === 'G' ||
                    menu.type === 'V' ||
                    menu.type === 'T' ||
                    menu.type === 'P'
                  "
                  >{{ menu.text }}</v-list-item-title
                >
                <v-list-item-title v-else>
                  <v-menu bottom right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-title
                        v-bind="attrs"
                        v-on="on"
                        @click="checkCardsForR()"
                        >{{
                          $t("$vuetify.paymentMethods.card")
                        }}</v-list-item-title
                      >
                    </template>
                    <v-list v-if="availableCards && availableCards.length > 0">
                      <v-list-item
                        v-for="(card, idx) in availableCards"
                        :key="idx"
                        @click="checkTerminal('K', 'R', undefined, card)"
                      >
                        <v-list-item-title>{{ card.name }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <v-row v-if="cashRegisterType === 'VP'">
        <v-col cols="12" style="padding: 0 0 2px 0">
          <v-menu bottom right>
            <template v-slot:activator="{ on: onMenu, attrs }">
              <v-tooltip
                bottom
                :open-delay="tooltipDelay"
                :open-on-hover="true"
                :open-on-click="false"
                :open-on-focus="false"
              >
                <template #activator="{ on: onTooltip }">
                  <div v-on="{ ...onMenu, ...onTooltip }">
                    <v-btn
                      block
                      tile
                      depressed
                      min-height="3.5rem"
                      icon
                      :class="[
                        { 'disable-events': !buttonsEnabled },
                        'white--text',
                        { darkblue: buttonsEnabled },
                      ]"
                      v-bind="attrs"
                    >
                      <span class="white--text tile btn-lg">Račun</span>
                    </v-btn>
                  </div>
                </template>
                <span>{{ $t("$vuetify.tooltips.payR1") }}</span>
              </v-tooltip>
            </template>
            <v-list>
              <v-list-item
                v-for="(menu, idx) in paymentMenu"
                :key="idx"
                @click="
                  menu.type !== 'K' ? menuPaymentClick(menu) : checkCards()
                "
              >
                <v-list-item-title
                  v-if="
                    menu.type === 'G' ||
                    menu.type === 'V' ||
                    menu.type === 'T' ||
                    menu.type === 'P'
                  "
                  >{{ menu.text }}</v-list-item-title
                >
                <v-list-item-title v-else>
                  <v-menu bottom right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-title
                        v-bind="attrs"
                        v-on="on"
                        @click="checkCards()"
                        >{{
                          $t("$vuetify.paymentMethods.card")
                        }}</v-list-item-title
                      >
                    </template>
                    <v-list v-if="availableCards && availableCards.length > 0">
                      <v-list-item
                        v-for="(card, idx) in availableCards"
                        :key="idx"
                        @click="checkTerminal('K', 'BO', undefined, card)"
                      >
                        <v-list-item-title>{{ card.name }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row> -->
    </v-container>
    <account-details-dialog v-if="r1DialogOpen"
      :data="data"
      :paymentMethod="paymentMethod"
      :selectedTable="selectedTable"
      :selectedTables="selectedTables"
      :selectedCard="selectedCard"
      :selectedPrinter="selectedPrinter"
      :selectedTerminal="selectedTerminal"
      @closeR1Dialog="onR1DialogClose"
      @imidiatePrintPrepare="imidiatePrintPrepare"
      @accountDetailsFinishPayment="onAccountDetailsFinishPayment"></account-details-dialog>
    <terminals ref="terminals"
      @terminalSelected="terminalSelected"></terminals>
    <card-payment-selection-dialog ref="cardSelectionDialog"
      :cards="availableCards"
      @checkTerminal="checkTerminal"></card-payment-selection-dialog>
    <r1-receipt-payment-dialog ref="r1ReceiptPaymentDialog"
      :paymentMenu="paymentMenu"
      @menuPaymentClick="menuPaymentClick"
      @checkCardsForR="checkCardsForR"></r1-receipt-payment-dialog>
    <ws-payment-selection-dialog ref="wsPaymentSelectionDialog"
      :paymentMenu="paymentMenu"
      @menuPaymentClick="menuPaymentClick"
      @checkCards="checkCards"></ws-payment-selection-dialog>
  </div>
</template>
<script>

import rules from '@/plugins/rules'
import state from '@/state'
import { df } from '@/plugins/firebase'
import { toISOLocal, clone } from '@/plugins/utils'
import AccountDetailsDialog from '@/modules/cash-register/components/dialogs/AccountDetailsDialog'
import PanelsToolbarSimple from '@/modules/cash-register/components/PanelsToolbarSimple'
import { printerInit } from '@/libs/bixolon/printer'
import { printOrder } from '@/libs/bixolon/order'
import Terminals from '@/modules/cash-register/components/dialogs/Terminals.vue'
import CardPaymentSelectionDialog from '@/modules/cash-register/components/dialogs/CardPaymentSelectionDialog'
import R1ReceiptPaymentDialog from '@/modules/cash-register/components/dialogs/R1ReceiptPaymentDialog'
import WsPaymentSelectionDialog from '@/modules/cash-register/components/dialogs/WsPaymentSelectionDialog'

import { remapItems } from '@/libs/receiptIssuing/items'
import { getPayload, getVirmanPayload } from '@/libs/receiptIssuing/payload'
import { getPaymentMethod } from '@/libs/receiptIssuing/commons'
import { sendHttp, sendVirmanHttp } from '@/libs/receiptIssuing/payment'

import EventBus from '@/plugins/event-bus'
import { retry } from '@/libs/receiptIssuing/validation'

export default {
  components: {
    AccountDetailsDialog,
    PanelsToolbarSimple,
    Terminals,
    CardPaymentSelectionDialog,
    R1ReceiptPaymentDialog,
    WsPaymentSelectionDialog
  },
  props: {
    data: {
      type: Object,
      default: () => { }
    },
    selectedPrinter: {
      // Passed over from PanelRows or PanelRowsWide
      type: Object,
      default: () => { }
    }
  },
  inject: ['showMsgBox', 'confirm', 'confirmClose', 'showLoader', 'hideLoader', 'openPdfPrint'],
  data: function () {
    return {
      r1DialogOpen: false,
      addTableDialogOpen: false,
      note: '',
      languages: [],
      language: undefined,
      receiptNumber: 1,
      bill_sequence: 0,
      offer_sequence: 0,
      last_receipt: '',
      receiptType: '',
      receiptTypes: [],
      defaultReceiptTypes: [],
      multiplier: 0,
      // fiscalize: false,
      issueDate: '',
      minIssueDate: '',
      maxIssueDate: '',
      rules: {
        req: rules.req(),
        oib: rules.oib(),
        gt: rules.gt,
        arrReq: rules.arrReq()
      },
      listeners: [],
      type: '',
      cashRegisterForTerminal: undefined,
      paymentMethod: '',
      keypadNumber: '',
      editMode: false,
      ordersView: false,
      selectedTable: {},
      selectedTables: [],
      // availableTables: [],
      // initialTable: {},
      printers: [],
      orgUnits: [],
      selectedTerminal: undefined,
      paymentMenu: [
        { text: this.$t('$vuetify.paymentMethods.cash'), type: 'G', icon: 'mdi-cash' },
        { text: this.$t('$vuetify.paymentMethods.card'), type: 'K', icon: 'mdi-card' },
        { text: this.$t('$vuetify.paymentMethods.transaction'), type: 'T', icon: 'mdi-card' },
        { text: this.$t('$vuetify.paymentMethods.virman'), type: 'V', icon: 'mdi-card' }
      ],
      availableCards: [],
      selectedCard: undefined,
      receiptNote: null,
      tooltipDelay: 500,
      previewReceiptType: 'R',
      currency: 'EUR',
      paymentReference: undefined
    }
  },
  computed: {
    storeReceiptNumber () {
      if (this.receiptType && this.receiptType.value === 'P') {
        return this.offer_sequence
      } else {
        return this.bill_sequence
      }
    },
    buttonsEnabled () {
      return this.data.items.length > 0
    },
    cashRegisterType () {
      return state.getCashRegister().type
    }
  },

  watch: {
    storeReceiptNumber (nv) {
      this.receiptNumber = nv + 1
    },
    editMode: {
      handler (nv, ov) {
      }
    },
    cashRegisterType: {
      handler (type) {
        if (type === 'VP') {
          this.paymentMenu = [
            { text: this.$t('$vuetify.paymentMethods.transaction'), type: 'T', icon: 'mdi-card' },
            { text: this.$t('$vuetify.paymentMethods.virman'), type: 'V', icon: 'mdi-card' },
            { text: this.$t('$vuetify.paymentMethods.offer'), type: 'P', icon: 'mdi-card' }
          ]
        } else if (type === 'ALL') {
          this.paymentMenu = [
            { text: this.$t('$vuetify.paymentMethods.cash'), type: 'G', icon: 'mdi-cash' },
            { text: this.$t('$vuetify.paymentMethods.card'), type: 'K', icon: 'mdi-card' },
            { text: this.$t('$vuetify.paymentMethods.transaction'), type: 'T', icon: 'mdi-card' },
            { text: this.$t('$vuetify.paymentMethods.virman'), type: 'V', icon: 'mdi-card' },
            { text: this.$t('$vuetify.paymentMethods.offer'), type: 'P', icon: 'mdi-card' }
          ]
        } else {
          this.paymentMenu = [
            { text: this.$t('$vuetify.paymentMethods.cash'), type: 'G', icon: 'mdi-cash' },
            { text: this.$t('$vuetify.paymentMethods.card'), type: 'K', icon: 'mdi-card' },
            { text: this.$t('$vuetify.paymentMethods.virman'), type: 'V', icon: 'mdi-card' }
          ]
        }
      },
      immediate: true
    }
    // selectedPrinter: {
    //   handler (nv, ov) {
    //     if (nv !== ov) {
    //
    //     }
    //   }
    // }
  },
  methods: {
    // Provjera i ponasanje ako nema aktivnih kartica
    checkCardsForR () {
      if (!this.availableCards || this.availableCards.length === 0) {
        if (this.cashRegisterForTerminal.terminals) {
          var terms = this.cashRegisterForTerminal.terminals
          var termsArray = []
          if (terms && Object.keys(terms).length > 0) {
            Object.keys(terms).forEach(key => {
              termsArray.push(terms[key])
            })

            // Ako postoji vise terminala, dopusti odabir bilo kojeg
            if (termsArray && termsArray.length > 1) {
              this.$refs.terminals.open(this.cashRegisterForTerminal, undefined, 'K', 'R')
            } else if (termsArray && termsArray.length === 1) {
              // Ako postoji ima samo jedan terminal, salji na njega
              this.selectedTerminal = termsArray[0]
              this.insertAccountDetails('K', 'R', undefined, undefined)
            } else {
              // Ako nema ni jedan terminal, samo napravi racun
              this.insertAccountDetails('K', 'R', undefined, undefined)
            }
          }
        } else {
          // Ako nema ni jedan terminal, samo napravi racun
          this.insertAccountDetails('K', 'R', undefined, undefined)
        }
      } else {
        this.openCardSelectionDialog('R')
      }
    },

    openCardSelectionDialog (type) {
      this.$refs.cardSelectionDialog.open(type)
    },
    openR1ReceiptPaymentDialog (mode) {
      this.$refs.r1ReceiptPaymentDialog.open(mode)
    },
    openWsPaymentDialog () {
      this.$refs.wsPaymentSelectionDialog.open()
    },

    // Provjera i ponasanje ako nema aktivnih kartica
    checkCards () {
      if (!this.availableCards || this.availableCards.length === 0) {
        // Nema aktivnih kartica
        if (this.cashRegisterForTerminal.terminals) {
          var terms = this.cashRegisterForTerminal.terminals
          var termsArray = []
          if (terms && Object.keys(terms).length > 0) {
            Object.keys(terms).forEach(key => {
              termsArray.push(terms[key])
            })

            // Ako postoji vise terminala, dopusti odabir bilo kojeg
            if (termsArray && termsArray.length > 1) {
              this.$refs.terminals.open(this.cashRegisterForTerminal, undefined, 'K', 'BO')
            } else if (termsArray && termsArray.length === 1) {
              // Ako postoji ima samo jedan terminal, salji na njega
              this.finishPayment('K', 'BO', undefined, undefined, termsArray[0])
            } else {
              // Ako nema ni jedan terminal, samo napravi racun
              this.finishPayment('K', 'BO', undefined, undefined)
            }
          }
        } else {
          // Ako nema ni jedan terminal, samo napravi racun
          this.finishPayment('K', 'BO', undefined, undefined)
        }
      } else {
        // Ima aktivnih kartica, pokaži dialog za odabir
        this.openCardSelectionDialog('BO')
      }
    },
    terminalSelected (terminal, paymentCard, method, receiptType) {
      this.selectedTerminal = terminal

      if (receiptType === 'R') {
        this.insertAccountDetails('K', 'R', undefined, paymentCard)
      } else {
        this.finishPayment(method, receiptType, undefined, paymentCard, terminal)
      }
    },
    checkTerminal (method, receiptType, deliveryName = undefined, paymentCard = undefined) {
      var termsWithCards = []

      if (this.cashRegisterForTerminal.terminals) {
        var terms = this.cashRegisterForTerminal.terminals
        Object.keys(terms).forEach(key => {
          if (terms[key].cards && terms[key].cards.length === 0) {
            termsWithCards.push(terms[key])
          }

          if (terms[key].cards && terms[key].cards.length > 0 && terms[key].cards.some(card => card.ID === paymentCard.id)) {
            termsWithCards.push(terms[key])
          }
        })
      }

      if (termsWithCards) {
        switch (termsWithCards.length) {
          case 0:
            if (receiptType === 'R') {
              this.insertAccountDetails('K', 'R', undefined, paymentCard)
            } else {
              this.finishPayment(method, receiptType, undefined, paymentCard)
            }
            break
          case 1:
            var terminal = termsWithCards[0]
            if (receiptType === 'R') {
              this.selectedTerminal = terminal
              this.insertAccountDetails('K', 'R', undefined, paymentCard)
            } else {
              this.finishPayment(method, receiptType, undefined, paymentCard, terminal)
            }
            break
          default:
            this.$refs.terminals.open(this.cashRegisterForTerminal, paymentCard, method, receiptType)
            break
        }
      }
    },

    confirmReprezentacija () {
      this.confirm({
        title: 'Reprezentacija',
        message: 'Ovaj račun će biti reprezentacija, jeste li sigurni da želite nastaviti?',
        options: {
          toolbar: true,
          width: 410,
          confirmText: this.$t('$vuetify.yes'),
          cancelText: this.$t('$vuetify.no')
        }
      }).then((resp) => {
        if (resp) {
          this.confirmClose()
          this.finishPayment('O', 'REPREZENTACIJA')
        }
      })
    },

    deliveryFinishSimplePayment (delivery, paymentType) {
      this.finishPayment(paymentType, 'BO', delivery, '')
    },
    menuPaymentClick (menu) {
      switch (menu.type) {
        case 'G':
          this.insertAccountDetails('G', 'R')
          break
        case 'K':
          this.insertAccountDetails('K', 'R')
          break
        case 'V':
          this.insertAccountDetails('V', 'R')
          break
        case 'T':
          this.insertAccountDetails('T', 'R')
          break
        case 'P':
          this.insertAccountDetails('P', 'R')
      }
    },

    openCloseBalanceDialog () {
      this.$emit('openCloseBalanceDialog')
    },
    openSearchItemsDialog () {
      this.$emit('openSearchItemsDialog')
    },
    openReceiptsDialog () {
      this.$emit('openReceiptsDialog')
    },

    clearOrderFromScreen () {
      this.data.items = []
      this.receiptNote = null
    },

    async imidiatePrintPrepare (items) {
      const itemsInOrgUnits = {}
      if (items && items.length > 0) {
        items.forEach(item => {
          if (item.organizational_unit_id && item.organizational_unit_id !== '') {
            if (!itemsInOrgUnits[item.organizational_unit_id]) {
              itemsInOrgUnits[item.organizational_unit_id] = []
            }
            itemsInOrgUnits[item.organizational_unit_id].push(item)
          }

          if (item.choosenProductOrder) {
            Object.keys(item.choosenProductOrder).forEach(key => {
              if (item.choosenProductOrder[key] && item.choosenProductOrder[key].length > 0) {
                item.choosenProductOrder[key].forEach(product => {
                  if (product && product.productItems) {
                    product.productItems.forEach(it => {
                      if (it.org_units && it.org_units[state.getPointOfSale().id]) {
                        if (item.choosenTagOrder[key]) {
                          it.tags = item.choosenTagOrder[key]
                        }
                        it.menuId = key
                        it.menuData = {
                          id: item.id,
                          name: item.name
                        }

                        if (!itemsInOrgUnits[it.org_units[state.getPointOfSale().id]]) {
                          itemsInOrgUnits[it.org_units[state.getPointOfSale().id]] = []
                        }

                        itemsInOrgUnits[it.org_units[state.getPointOfSale().id]].push(it)
                      }
                    })
                  }
                })
              }
            })
          }
        })

        var otherUnits = []
        Object.keys(itemsInOrgUnits).forEach(unitId => {
          this.orgUnits.forEach(orgUnit => {
            if (orgUnit.id === unitId) {
              otherUnits.push(orgUnit)
            }
          })
        })

        Object.keys(itemsInOrgUnits).forEach(unitId => {
          this.orgUnits.forEach(orgUnit => {
            if (orgUnit.id === unitId) {
              this.printers.forEach(printer => {
                // TODO maknuti ovu provjeru sa name, to je izmjena u id
                if (printer.id === orgUnit.printer || printer.name === orgUnit.printer) {
                  this.imidiatePrint(itemsInOrgUnits[unitId], printer, orgUnit, otherUnits)
                }
              })
            }
          })
        })
      }
    },

    async imidiatePrint (items, printer, orgUnit, otherUnits) {
      state.setPosPrinterSettings(2)
      printOrder(items, printer, undefined, undefined, orgUnit, otherUnits, undefined, this.hideLoader)
    },

    onR1DialogClose () {
      this.r1DialogOpen = false
    },
    async getCashRegisterNumber (receiptType, offer = false) {
      var cashRegister = await retry(this.getCashRegister, 3)

      if (!cashRegister) {
        return
      }
      if (offer === true) {
        return cashRegister.offer_sequence + 1
      } else {
        return cashRegister.bill_sequence + 1
      }
    },
    async getCashRegister () {
      const response = await df.doc(`cash_registers/${state.getCashRegister().id}`).get()
      if (!response.data()) {
        throw new Error('Nije moguće dohvatiti podatke o blagajni')
      } else {
        return response.data()
      }
    },

    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },
    setDefaults () {
      this.languages = [
        { name: this.$t('$vuetify.receiptLanguages.hr'), value: 'hr' },
        { name: this.$t('$vuetify.receiptLanguages.en'), value: 'en' }
      ]
      this.language = this.languages[0]
      this.maxIssueDate = toISOLocal(new Date())
      this.issueDate = ''
    },

    detachListeners () {
      this.listeners.forEach((listener) => {
        listener()
        this.listeners = this.listeners.filter((l) => l !== listener)
      })
    },

    dateTimeToUnixTimestamp (dt) {
      return Math.floor(new Date(toISOLocal(dt)).getTime() / 1000)
    },

    insertAccountDetails (method, receiptType, deliveryName = undefined, paymentCard = undefined) {
      if (receiptType === 'R') {
        if (paymentCard && paymentCard.name && paymentCard.name !== '') {
          this.selectedCard = paymentCard
        }
        this.paymentMethod = method
        this.r1DialogOpen = true
      }
    },
    onAccountDetailsFinishPayment (data) {
      this.type = data.type
      this.paymentReference = data.pnb ? data.pnbModel + ' ' + data.pnb : ''

      if (data.selectedCostCenter) {
        this.selectedCostCenter = data.selectedCostCenter
      }

      var selectedCard = this.selectedCard
      var selectedTerminal = this.selectedTerminal
      this.finishPayment(this.paymentMethod, 'R', undefined, selectedCard, selectedTerminal)
    },

    /// FINISH PAYMENT
    async finishPayment (method, receiptType, deliveryName = undefined, paymentCard = undefined, terminal = undefined) {
      if ((state.getCashRegister().type === 'MP' || state.getCashRegister().type === 'ALL') && (method === 'T' || method === 'V' || method === 'P') && this.data.discount && this.data.discount.amount > 0) {
        var type = 'Transakcijski račun'
        if (method === 'V') {
          type = 'Virman'
        }
        if (method === 'P') {
          type = 'Ponuda'
        }

        this.showMsgBox({
          title: type,
          text: type + ' se ne može izdati sa popustom na račun. Ukoliko želite nastaviti zamijenite popust na iznos sa popustom na artikle.',
          actions: ['cancel'],
          cancelBtnText: this.$t('$vuetify.close'),
          color: 'error'
        })

        return
      }
      if (this.selectedTable === undefined || this.selectedTable.id === '') {
        this.imidiatePrintPrepare(this.data.items)
      }

      var currency = 'HRK'
      if (Date.now() / 1000 > 1672527599) {
        currency = 'EUR'
      }

      if (receiptType === 'REPREZENTACIJA') {
        var reprezentacijaAmount = 200
        if (Date.now() / 1000 > 1672527599) {
          reprezentacijaAmount = 50
        }
        this.showLoader(this.$options.filters.money(reprezentacijaAmount, 100, currency), { opacity: 1 })
      } else {
        this.showLoader(this.$options.filters.money(this.data.total, 100, currency), { opacity: 1 })
      }

      let entity = 'receipt'
      if (method === 'V') {
        entity = 'virman'
      }
      var flag = 'BO'
      var fiscalize = true
      var offer = false
      if (method === 'P') {
        flag = 'P'
        entity = 'offer'
        offer = true
      }

      try {
        const items = await remapItems(this.data, method)
        const paymentMethod = getPaymentMethod(method)

        switch (paymentMethod) {
          case 'NOVČANICE':
            fiscalize = true
            break
          case 'KARTICA':
            fiscalize = true
            break
          case 'OSTALO':
            fiscalize = true
            break
          case 'TRANSAKCIJSKI RAČUN':
            fiscalize = false
            break
        }

        const args = {
          entity: entity,
          paymentMethod: paymentMethod,
          paymentReference: this.paymentReference ?? '',
          language: this.language,
          fiscalize: fiscalize,
          type: { flag: flag }
        }

        if (this.type) {
          args.type = clone(this.type)
          this.type = ''
        }

        try {
          args.receiptNumber = await this.getCashRegisterNumber(receiptType, offer)
        } catch (err) {
          this.hideLoader()
          EventBus.$emit('showError', { message: err })
          return
        }

        var payload = await getPayload(this.data, items, args, state, this.receiptNote)
        if (entity === 'virman') {
          payload = await getVirmanPayload(this.data, items, args, state, this.receiptNote)
        }

        if (terminal) {
          payload.terminal = terminal
        }

        const httpArgs = {
          issueDateTime: this.dateTimeToUnixTimestamp(new Date()),
          paymentMeth: paymentMethod,
          language: this.language,
          type: this.type,
          receiptNumber: this.receiptNumber,
          openPdfPrint: this.openPdfPrint,
          hideLoader: this.hideLoader,
          removeOrderFromTable: this.removeOrderFromTable,
          fiscalize: fiscalize
        }

        if (this.selectedCostCenter && this.selectedCostCenter.id !== -1) {
          httpArgs.costCenter = this.selectedCostCenter
        }

        try {
          httpArgs.receiptNumber = await this.getCashRegisterNumber(receiptType, offer)
        } catch (err) {
          this.hideLoader()
          EventBus.$emit('showError', { message: err })
          return
        }

        if (receiptType && receiptType !== '') {
          switch (receiptType) {
            case 'REPREZENTACIJA':
              httpArgs.reprezentacija = true
              break
            case 'DELIVERY':
              httpArgs.delivery = true
              httpArgs.deliveryName = deliveryName
              break
          }
        }

        if (deliveryName && deliveryName !== '') {
          httpArgs.delivery = true
          httpArgs.deliveryName = deliveryName
        }

        if (paymentCard && paymentCard.type && paymentCard.type !== '') {
          httpArgs.paymentCard = paymentCard.type
        }

        if (paymentCard && paymentCard.name && paymentCard.name !== '') {
          httpArgs.paymentCard = paymentCard.name
        }

        httpArgs.msgBox = this.showMsgBox
        httpArgs.confirm = this.confirm
        httpArgs.confirmClose = this.confirmClose

        const additionalData = {
          callNewPrint: true
        }

        if (entity === 'virman') {
          sendVirmanHttp(this.data, items, payload, httpArgs, this.selectedPrinter, additionalData)
        } else {
          sendHttp(this.data, items, payload, httpArgs, this.selectedPrinter, additionalData)
        }

        this.receiptNote = null
        this.setDefaults()
      } catch (err) {
        this.showMsgBox({
          text: err && err !== '' ? err : 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: this.$t('$vuetify.close'),
          color: 'error'
        })
      }
    },
    getCards () {
      const that = this
      df
        .doc(`company_cards/${state.getCurrentCompany().id}`)
        .onSnapshot((doc) => {
          if (
            doc &&
            doc.data() &&
            doc.data().cards &&
            doc.data().cards.length > 0
          ) {
            that.availableCards = doc.data().cards.filter(card => card.active === true)
          }
          that.availableCards = that.availableCards.sort((a, b) => a.name.localeCompare(b.name))
        })
    }
  },

  created () {
    this.tooltipDelay = state.getTooltipDelay()
    // this.selectedTable = new Table()
    // this.getTables()
    // this.resetTables()
  },

  mounted () {
    this.data.currency = 'HRK'
    if (Date.now() / 1000 > 1672527599) {
      this.data.currency = 'EUR'
    }

    this.selectedCard = undefined

    if (state.getPrinter() === 'BIXOLON80') {
      const issueID = 1
      printerInit(issueID)
      state.setPosPrinterSettings(2)
    }

    // EventBus.$off('set-mode')
    EventBus.$on('set-mode', (editMode) => {
      this.editMode = editMode
    })

    EventBus.$off('set-orders-view')
    EventBus.$on('set-orders-view', (ordersView) => {
      this.ordersView = ordersView
    })

    EventBus.$off('save-receipt-note')
    EventBus.$on('save-receipt-note', (receiptNote) => {
      this.receiptNote = receiptNote
    })

    EventBus.$off('deliveryFinishSimplePayment')
    EventBus.$on('deliveryFinishSimplePayment', (deliveryService, paymentMethod) => {
      this.finishPayment(paymentMethod, 'DELIVERY', deliveryService, '')
    })

    this.getCards()

    this.getMultiplier()
    this.setDefaults()
    this.minIssueDate = toISOLocal(new Date())
    this.listeners.push(
      df
        .doc(`cash_registers/${state.getCashRegister().id}`)
        .onSnapshot((doc) => {
          if (doc && doc.data()) {
            this.offer_sequence = doc.data().offer_sequence
            this.bill_sequence = doc.data().bill_sequence
            this.last_receipt = doc.data().last_receipt_issued_timestamp
            this.cashRegisterForTerminal = doc.data()
            if (this.last_receipt) {
              this.minIssueDate = toISOLocal(
                new Date(this.last_receipt * 1000)
              )
            } else {
              this.minIssueDate = toISOLocal(new Date())
            }
          }
        })
    )

    this.orgUnits = []
    let query = df.collection(`location_units/${state.getPointOfSale().id}/units`)
    let listener = query.onSnapshot((doc) => {
      doc.docs.forEach((rec) => {
        this.orgUnits.push(rec.data())
      })
    })
    this.listeners.push(listener)

    this.printers = []
    query = df.collection(`location_printers/${state.getPointOfSale().id}/printers`)
    listener = query.onSnapshot((doc) => {
      doc.docs.forEach((rec) => {
        this.printers.push(rec.data())
      })
    })

    this.listeners.push(listener)
  },
  beforeDestroy () {
    if (this.listeners) {
      this.detachListeners()
    }
  }
}

</script>

<style scoped>
#container {
  overflow-x: hidden;
  overflow-y: hidden;
  cursor: grab;
  touch-action: none;
  /* border: 1px solid#fd0000; */
  margin-top: 12px;
}

@media only screen and (max-height: 899px) {
  #container {
    max-height: 193px;
  }

  span.tile {
    display: block;
    white-space: normal;
    max-width: 180px;
  }
}

@media only screen and (min-height: 900px) {
  #container {
    max-height: 440px;
  }

  span.tile {
    display: block;
    white-space: normal;
    max-width: 120px;
  }
}

.panel-cell {
  padding: 0 2px 2px 0;
}

.label {
  font-size: 0.6rem;
  color: white;
  min-width: 90px;
}

.text-wrap {
  width: min-content;
  margin: auto;
}

.disable-events {
  pointer-events: none;
  background-color: #e0e0e0;
}

.accent-btn {
  animation: glowing 700ms infinite;
  font-weight: 700;
}

.darkblue {
  background-color: #2c5a90;
}

.btn-lg {
  font-size: 1rem;
  font-weight: 700;
}

.btn-md {
  font-size: 0.8rem;
  font-weight: 700;
}

.btn-sm {
  font-size: 0.7rem;
  font-weight: 700;
}

.btn-purple {
  color: white;
  background-color: #b10090;
}
</style>
