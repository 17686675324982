<template>
  <v-dialog v-model="dialogOpen"
    v-if="dialogOpen"
    content-class="my-custom-dialog"
    persistent
    max-width="550"
    style="z-index: 6; height: auto !important">
    <v-overlay v-if="loading"
      absolute
      z-index="10">
      <v-progress-circular indeterminate
        size="64"
        color="primary"></v-progress-circular>
    </v-overlay>
    <v-form ref="accountForm"
      @submit.prevent="finishPayment">
      <v-card tile
        color="white"
        class="pb-3">

        <v-card-title class="d-flex"
          style="background-color: #1577da; height: 80px; color: white;">
          <span class="text-h5"
            style="font-weight: bold">{{ $t("$vuetify.accountDetailsTitle") }}</span>

          <v-spacer></v-spacer>
          <span :style="{ color: saveAssociateEnabled ? 'white' : '#d9d9d9' }"
            style=" color: white; font-size: 17px !important">
            {{ $t("$vuetify.formDetails.saveAssociate") }}
          </span>
          <v-checkbox :color="saveAssociateEnabled ? 'white' : 'grey darken-4'"
            class="ml-2 custom-checkbox"
            :disabled="!saveAssociateEnabled"
            v-model="doSaveAccount"></v-checkbox>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="input-row">
              <v-col class="pt-2 mt-0 pr-1">
                <v-combobox clearable
                  @focus="showKbd"
                  data-layout="normalAduro"
                  :label="$t('$vuetify.formDetails.oib')"
                  v-model="payerOib"
                  class=""
                  dense
                  hide-details
                  outlined
                  :search-input.sync="payerOib"
                  :items="payer ? payer.oibs : []"
                  validate-on-blur
                  @keydown.enter="finishPayment"
                  :class="['uppercased-input', 'textField']">
                  <template v-slot:item="{ item, on, attrs }">
                    <v-list-item @keydown.enter.stop=""
                      v-on="on"
                      v-bind="attrs">{{ item }}</v-list-item>
                  </template>
                </v-combobox>
              </v-col>
              <v-col class="pt-2 mt-0 pl-1">
                <v-combobox @focus="showKbd"
                  data-layout="normalAduro"
                  :label="$t('$vuetify.formDetails.name')"
                  :placeholder="$t('$vuetify.filterPlaceholderLabel')"
                  v-model="payer"
                  :loading="loading"
                  :items="associates"
                  return-object
                  item-text="name"
                  item-value="name"
                  @change="selectAssociate(payer)"
                  :search-input.sync="payerName"
                  @update:search-input="querySelections"
                  hide-no-data
                  clearable
                  outlined
                  hide-details
                  dense
                  @click:clear="setForm(false)"
                  :rules="[rules.req]"
                  @keydown.enter="finishPayment"
                  :class="['uppercased-input', 'textField']">
                  <template v-slot:item="{ item, on, attrs }">
                    <v-list-item class="uppercased-input"
                      @keydown.enter.stop=""
                      v-on="on"
                      v-bind="attrs">{{ item.name }}</v-list-item>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>

            <v-row class="input-row mt-0">
              <v-col class="mt-0 pr-1">
                <v-text-field @focus="showKbd"
                  dense
                  hide-details
                  outlined
                  data-layout="normalAduro"
                  :class="['uppercased-input', 'textField']"
                  v-model="payerAddress"
                  :label="$t('$vuetify.formDetails.address')"
                  :rules="payer && payer.name === 'Kupac građanin' ? [] : [rules.req]
                    "></v-text-field>
              </v-col>
              <v-col class="mt-0 pl-1">
                <v-text-field @focus="showKbd"
                  data-layout="normalAduro"
                  dense
                  outlined
                  hide-details
                  v-model="payerCity"
                  :label="$t('$vuetify.formDetails.city')"
                  :rules="payer && payer.name === 'Kupac građanin' ? [] : [rules.req]
                    "
                  :class="['uppercased-input', 'textField']"></v-text-field>
              </v-col>
            </v-row>

            <v-row class="input-row mt-3">
              <v-col class="pt-0 mt-0 pr-1">
                <v-text-field @focus="showKbd"
                  data-layout="normalAduro"
                  dense
                  outlined
                  hide-details
                  :label="$t('$vuetify.formDetails.zipCode')"
                  v-model="payerZip"
                  :rules="payer && payer.name === 'Kupac građanin' ? [] : [rules.req]
                    "
                  :class="['uppercased-input', 'textField']"></v-text-field>
              </v-col>
              <v-col class="pt-0 mt-0 pl-1">
                <v-select @focus="showKbd"
                  data-layout="normalAduro"
                  :items="countries"
                  dense
                  hide-details
                  outlined
                  :label="$t('$vuetify.formDetails.country')"
                  v-model="payerCountry"
                  item-text="country"
                  item-value="country"
                  :rules="payer && payer.name === 'Kupac građanin' ? [] : [rules.req]
                    "
                  :class="['uppercased-input', 'textField']"></v-select>
              </v-col>
            </v-row>
            <v-row class="input-row pt-1 mt-0">
              <v-col cols="6"
                class="pr-1">

                <v-select :items="costCenters"
                  label="Odaberite mjesto troška"
                  v-model="selectedCostCenter"
                  outlined
                  dense
                  item-text="name"
                  :class="['uppercased-input', 'textField']"
                  hide-details
                  return-object>
                  <template v-slot:item="{ item }">
                    <span>{{ item.name }} {{ item.description ? ' - ' + item.description : '' }}
                      {{ item.address && item.address.street ? ', ' + item.address.street : "" }}
                      {{ item.address && item.address.city ? item.address.city : "" }}
                    </span>
                  </template>
                </v-select>
              </v-col>

              <v-col cols="2"
                class="pr-0">
                <v-btn @click="openCostCenterDialog()"
                  style="border-radius: 12px !important"
                  class="btn successButton textfield"><v-icon>mdi-plus</v-icon></v-btn>
              </v-col>
            </v-row>
            <v-row class="input-row pt-1 mt-0">
              <v-col>
                <v-select :items="pnbTypes"
                  v-model="pnbType"
                  class="textField"
                  item-text="text"
                  item-value="value"
                  persistent-placeholder
                  outlined
                  dense
                  @change="onPnbTypeChange">
                </v-select>
              </v-col>
            </v-row>
            <v-row class="input-row pt-1 mt-0">
              <v-col cols="3"
                class="pr-1">
                <v-text-field v-model="pnbModel"
                  prefix="HR"
                  label="Model"
                  class="textField"
                  persistent-placeholder
                  outlined
                  dense></v-text-field>
              </v-col>
              <v-col cols="9"
                class="pl-1">
                <v-text-field v-model="pnb"
                  label="Poziv na broj"
                  class="textField"
                  persistent-placeholder
                  outlined
                  dense></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="
              inEu === true &&
              payerCountry !== 'Hrvatska' &&
              payerCountry !== undefined
            "
              class="pt-0 mt-0">
              <v-col cols="12"
                class="d-flex pt-0 mt-0 py-0 justify-end">
                <v-btn class="mt-10 mr-10"
                  href="https://ec.europa.eu/taxation_customs/vies/?locale=hr"
                  target="_blank"
                  height="25">
                  Provjera VIES baze
                </v-btn>
                <v-radio-group v-model="selectedVies"
                  :rules="[(v) => !!v || 'Molimo odaberite']"
                  required
                  class="small-radio">
                  <v-radio v-for="vies in vieses"
                    :key="vies.value"
                    :value="vies.value"
                    :label="vies.name"
                    :rules="[rules.req]"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row v-if="
              inEu === false &&
              payerCountry !== 'Hrvatska' &&
              payerCountry !== undefined
            "
              class="pt-0 mt-0">
              <v-col cols="12"
                class="d-flex pt-0 mt-0 py-0 justify-end">
                <v-radio-group v-model="selectedCustomerType"
                  :rules="[(v) => !!v || 'Molimo odaberite']"
                  required
                  class="small-radio">
                  <v-radio v-for="type in customerTypes"
                    :key="type.value"
                    :value="type.value"
                    :label="type.name"
                    :rules="[rules.req]">
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

          </v-container>
        </v-card-text>

        <v-card-actions class="d-flex pt-0 mt-0 pr-7 justify-end">
          <v-btn class="btn dangerButton"
            @click="backwardStep">Nazad</v-btn>
          <v-btn id="success"
            class="btn successButton"
            :loading="submitting"
            type="submit">
            {{ $t("$vuetify.finishPayment") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

    <v-container class="kbd"
      v-if="!$vuetify.breakpoint.smAndDown"
      ref="keyboard"
      v-show="oskVisible"
      :style="{ 'max-width': width }">
      <div class="kbd-header"></div>
      <vue-touch-keyboard :options="oskOptions"
        :layout="layout"
        :cancel="hideKbd"
        :accept="accept"
        :input="input" />
      <cost-center-dialog @addCostCenter="addCostCenter"
        ref="costCenterDialog"></cost-center-dialog>
    </v-container>
  </v-dialog>
</template>

<script>
import state from '@/state'
import rules from '@/plugins/rules'
import countries from '@/mixins/countries'
import { clone, toISOLocal } from '@/plugins/utils'
import { df } from '@/plugins/firebase'
import { v4 as uuidv4 } from 'uuid'
import { printerInit } from '@/libs/bixolon/printer'
import onScreenKeyboard from '@/mixins/onScreenKeyboard'
import { callAssociates } from '@/api/associates'
import CostCenterDialog from '@/modules/company/components/CostCenterDialog.vue'

export default {
  components: {
    CostCenterDialog
  },
  mixins: [countries, onScreenKeyboard],
  inject: ['showMsgBox', 'showLoader', 'hideLoader', 'openPdfPrint'],
  data () {
    return {
      templatesDialogOpen: false,
      dialogOpen: false,
      loading: false,
      listeners: [],
      defaultAssociates: [],
      associates: [],
      fiscalize: false,
      payerName: undefined,
      payer: undefined,
      payerAddress: undefined,
      payerCity: undefined,
      payerZip: undefined,
      payerCountry: undefined,
      payerOib: undefined,
      rules: {
        req: rules.req(),
        oib: rules.oib()
      },
      inEu: false,
      languages: [],
      language: {},
      note: undefined,
      receiptNumber: 1,
      offer_sequence: undefined,
      bill_sequence: undefined,
      last_receipt: undefined,
      minIssueDate: undefined,
      flag: undefined,
      doSaveAccount: false,
      saveAssociateEnabled: true,
      submitting: false,
      euCountries: [],
      takenOibs: [],
      selectedVies: undefined,
      selectedCustomerType: undefined,
      wholeSaleRcptTypes: undefined,
      costCenters: [],
      selectedCostCenter: undefined,
      pnb: undefined,
      pnbModel: undefined,
      pnbType: 0,
      pnbTypes: [{ text: 'Generiraj poziv po broju računa', value: 0, disabled: false }, { text: 'Generiraj poziv po OIB-u', value: 1, disabled: true }]
    }
  },

  props: {
    data: {
      type: Object,
      default: () => { }
    },
    r1DialogOpen: {
      type: Boolean,
      default: false
    },
    paymentMethod: {
      type: String,
      default: ''
    },
    // selectedTable: {
    //   type: Object,
    //   default: () => {}
    // },
    // selectedTables: {
    //   type: Array,
    //   default: () => []
    // },
    selectedCard: {
      type: Object,
      default: () => { }
    },
    selectedPrinter: {
      type: Object,
      default: () => { }
    },
    selectedTerminal: {
      type: Object,
      default: () => { }
    },
    showPreview: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    payerCountry (nv) {
      this.inEu = false
      this.euCountries.forEach(country => {
        if (country === nv) {
          this.inEu = true
        }
      })
    },
    storeReceiptNumber (nv) {
      this.receiptNumber = nv + 1
      this.calculateReferenceNumber()
    },
    payerOib (nv) {
      if (nv && nv.length === 11) {
        if (this.takenOibs.includes(nv)) {
          this.doSaveAccount = false
          this.saveAssociateEnabled = false
        } else {
          this.saveAssociateEnabled = true
        }
      }
    }
  },

  computed: {
    receiptNum () {
      if (!state.getPointOfSale()) { return 'Broj računa' }
      if (!state.getCashRegister()) { return 'Broj računa' }

      return this.receiptNumber +
        state.getCurrentCompany().receipt_numeration_separator +
        state.getPointOfSale().location_id +
        state.getCurrentCompany().receipt_numeration_separator +
        state.getCashRegister().number
    },
    vieses () {
      return [
        { name: 'Kupac unutar VIES baze', value: 'inside' },
        { name: 'Kupac izvan VIES baze', value: 'outside' }
      ]
    },
    customerTypes () {
      return [
        { name: 'Kupac - pravna osoba', value: 'legal' },
        { name: 'Kupac - fizička osoba', value: 'person' }
      ]
    },
    storeReceiptNumber () {
      if (this.receiptType && this.receiptType.value === 'P') {
        return this.offer_sequence
      } else {
        return this.bill_sequence
      }
    }
  },

  methods: {
    onPnbTypeChange () {
      this.calculateReferenceNumber()
    },
    calculateReferenceNumber () {
      let model = '00'
      let receiptNum = clone(this.receiptNum)
      receiptNum = receiptNum.replaceAll('/', '-')
      if (this.pnbType === 0) {
        let formattedReceiptNumber = receiptNum.replace(/[^0-9-]+/, '')
        formattedReceiptNumber = formattedReceiptNumber.replaceAll('--', '-')
        this.pnbModel = model
        this.pnb = formattedReceiptNumber
      } else if (this.pnbType === 1) {
        const oib = this.getOib()
        if (!oib) return
        model = '01'
        const randomKey = Math.floor(Math.random() * (99999999 - 10000000 + 1)) + 10000000
        const forSecurity = oib.toString() + randomKey.toString()
        const securityNum = this.controlDigit(forSecurity)
        this.pnbModel = model
        this.pnb = oib + '-' + randomKey + '-' + securityNum
      }
    },
    getOib () {
      let associate

      if (this.payer) {
        associate = this.payer
      }
      if (this.payerOib && typeof (this.payerOib) !== 'string') {
        associate = this.payerOib
      }

      if (typeof (associate) === 'string') {
        // slobodan unos
        return this.payerOib
      } else {
        // Odabir
        if (associate) {
          return associate.oibs[0]
        }
      }
    },
    controlDigit (s) {
      var i = 0; var v = 0; var p = 2; var c = ' '
      for (i = s.length; i >= 1; i--) {
        c = s.substr(i - 1, 1)
        if (c >= '0' && c <= '9' && v >= 0) { v = v + p * c; p = p + 1 } else { v = -1 }
      };
      if (v >= 0) { v = 11 - (v % 11); if (v > 9) { v = 0 }; }
      return (v)
    },
    addCostCenter (costCenter) {
      var cc = clone(costCenter)
      cc.address.zip_code = cc.address.zipCode
      this.costCenters.push(cc)
    },
    openCostCenterDialog () {
      this.$refs.costCenterDialog.open(false)
    },
    selectAssociate (payer) {
      this.setForm(true)
      this.setCostCenters(payer)
      this.calculateReferenceNumber()
      this.pnbTypes[1].disabled = false
    },
    setCostCenters (associate) {
      this.costCenters = []
      if (associate && associate.cost_centers) {
        this.costCenters = Object.values(associate.cost_centers)
      }
      this.costCenters.push({ id: -1, name: 'Osnovno mjesto troška' })
    },
    setDefaults () {
      this.fiscalize = true
      this.languages = [
        { name: this.$t('$vuetify.receiptLanguages.hr'), value: 'hr' },
        { name: this.$t('$vuetify.receiptLanguages.en'), value: 'en' }
      ]
      this.language = this.languages[0]
      this.note = ''
      this.flag = 'R'
    },

    setForm (data = true) {
      if (data) {
        this.referenceNumber = ''
        if (this.payer && typeof this.payer === 'object') {
          // this.payerCountry = this.payer.country
          this.payerCountry = 'Hrvatska'
          this.payerOib = this.payer.oibs ? this.payer.oibs[0] : ''
          this.payerAddress = this.payer.address
          this.payerCity = this.payer.city
          this.payerZip = this.payer.zip_code

          this.saveAssociateEnabled = false
        }
      } else {
        this.referenceNumber = ''
        this.associates = clone(this.defaultAssociates)
        this.saveAssociateEnabled = true
        this.$nextTick(() => {
          // this.$refs.accountForm.reset()
        })
      }
    },

    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.paymentModels = doc.data().payment_models
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },

    getEuCountries () {
      const listener = df.doc('project_constants/countries').onSnapshot(doc => {
        this.euCountries = doc.data().eu_countries
      })
      this.listeners.push(listener)
    },

    querySelections () {
      if (this.payerName) {
        this.associates = this.defaultAssociates.filter((associate) =>
          associate.name.toLowerCase().includes(this.payerName.toLowerCase())
        )
      } else {
        this.associates = clone(this.defaultAssociates)
      }
    },

    backwardStep () {
      this.setForm(false)
      this.$emit('closeR1Dialog')
    },

    dateTimeToUnixTimestamp (dt) {
      return Math.floor(new Date(toISOLocal(dt)).getTime() / 1000)
    },

    async finishPayment () {
      if (!this.$refs.accountForm.validate()) return
      const type = {
        flag: this.flag,
        payer_name: this.payerName,
        payer_address: this.payerAddress,
        payer_city: this.payerCity,
        payer_zip_code: this.payerZip,
        payer_country: this.payerCountry,
        payer_oib: this.payerOib
      }

      if (this.doSaveAccount) {
        if (this.takenOibs.includes(type.payer_oib)) {
          console.log('oib vec postoji')
          this.doSaveAccount = false
          this.saveAssociateEnabled = false
          return
        }
        this.saveAssociate()
      }

      let wholeSaleRcptType = ''

      // POTREBNO ZBOG ISPISA ČLANKA NA RAČUNU
      if (this.payerCountry !== undefined && this.payerCountry !== 'Hrvatska') {
        if (state.getCurrentCompany().is_taxed === true) {
          if (this.inEu) {
            if (this.selectedVies === 'inside') {
              wholeSaleRcptType = 'TAX_EU_VIES_TRUE'
            } else {
              wholeSaleRcptType = 'TAX_EU_VIES_FALSE'
            }
          } else {
            if (this.selectedCustomerType === 'person') {
              wholeSaleRcptType = 'TAX_OTHER_PERSON'
            } else {
              wholeSaleRcptType = 'TAX_OTHER_LEGAL_ENTITY'
            }
          }
        } else {
          if (this.inEu) {
            if (this.selectedVies === 'inside') {
              wholeSaleRcptType = 'EU_VIES_TRUE'
            } else {
              wholeSaleRcptType = 'EU_VIES_FALSE'
            }
          } else {
            if (this.selectedCustomerType === 'person') {
              wholeSaleRcptType = 'OTHER_PERSON'
            } else {
              wholeSaleRcptType = 'OTHER_LEGAL_ENTITY'
            }
          }
        }
      }

      const data = {
        type: type,
        card: this.selectedCard,
        wholesale_receipt_type: wholeSaleRcptType
      }

      if (this.selectedCostCenter && this.selectedCostCenter.id !== -1) {
        data.selectedCostCenter = this.selectedCostCenter
      }

      if (this.pnb) {
        data.pnb = this.pnb
        data.pnbModel = 'HR' + this.pnbModel
      }

      if (this.showPreview === false) {
        this.$emit('accountDetailsFinishPayment', data)
      } else {
        this.$emit('accountDetailsPreviewReceipt', data)
      }

      this.setForm(false)
      this.$emit('closeR1Dialog')
    },

    async saveAssociate () {
      const oibs = [this.payerOib]
      const payload = {
        action: {
          operation: 'set',
          entity: 'associate',
          params: {
            company_id: state.getCurrentCompany().id,
            name: this.payerName.toLowerCase(),
            oibs: oibs,
            address: this.payerAddress.toLowerCase(),
            city: this.payerCity.toLowerCase(),
            zip_code: this.payerZip,
            country: this.payerCountry,
            associate_type: { BUYER: true }
          }
        }
      }

      if (this.costCenters && this.costCenters.length > 0) {
        payload.action.params.cost_centers = this.costCenters.reduce((acc, center) => {
          if (center.id !== -1) {
            var id = uuidv4()
            center.id = id
            acc[id] = center
          }
          return acc
        }, {})
      }

      var path = `/associates/${state.getCurrentCompany().id}/associate`
      var response = await callAssociates('post', path, payload.action.params)

      if (response.status !== 200) {
        this.showMsgBox({
          text: 'Neuspjelo dodavanje novog klijenta',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        this.submitting = false
      }
    },

    detachListeners () {
      this.listeners.forEach((listener) => {
        listener()
        this.listeners = this.listeners.filter((l) => l !== listener)
      })
    },
    async getAssociates () {
      var path = `/associates/${state.getCurrentCompany().id}/associate`
      var response = await callAssociates('get', path)

      if (response && response.status === 200 && response.data && response.data.associates) {
        this.defaultAssociates = Object.keys(response.data.associates)
          .map((key) => {
            return response.data.associates[key]
          })
          .filter((associate) => associate.status === 'OK')

        this.defaultAssociates.push({ name: 'Kupac građanin', id: 'kupac-gradanin' })
        this.associates = clone(this.defaultAssociates.sort((a, b) => a.name.localeCompare(b.name)))
        // Get all used up OIBs
        this.associates.forEach(item => {
          if (item?.oibs && item.oibs.length > 0) {
            item.oibs.forEach(oib => {
              this.takenOibs.push(oib)
            })
          }
        })
      } else if (response.status === 404) {
        this.associates = []
        this.showMsgBox({
          text: 'Klijenti nisu pronađeni.',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'warning'
        })
      } else {
        this.showMsgBox({
          text: 'Neuspjelo dohvaćanje klijenata',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
      }
    }

  },

  created () {
    this.dialogOpen = true
  },

  mounted () {
    // document.getElementById('oibInput').click()
    if (state.getPrinter() === 'BIXOLON80') {
      const issueID = 1
      printerInit(issueID)
      state.setPosPrinterSettings(2)
    }

    this.setDefaults()
    this.getMultiplier()
    this.getEuCountries()

    this.payerCountry = 'Hrvatska'

    this.getAssociates()

    this.minIssueDate = toISOLocal(new Date())
    this.calculateReferenceNumber()

    this.listeners.push(
      df
        .doc(`cash_registers/${state.getCashRegister().id}`)
        .onSnapshot((doc) => {
          if (doc && doc.data()) {
            this.offer_sequence = doc.data().offer_sequence
            this.bill_sequence = doc.data().bill_sequence
            this.last_receipt = doc.data().last_receipt_issued_timestamp
            if (this.last_receipt) {
              this.minIssueDate = toISOLocal(
                new Date(this.last_receipt * 1000)
              )
            } else {
              this.minIssueDate = toISOLocal(new Date())
            }
          }
        })
    )
  },

  beforeDestroy () {
    this.detachListeners()
  }
}
</script>

<style scoped>
::v-deep .small-radio i {
  font-size: 19px;
}

::v-deep .small-radio label {
  font-size: 14px;
  padding-left: 0px;
  margin-left: -4px;
}

::v-deep .small-radio .v-radio {
  padding: 0px;
}

::v-deep .small-radio [class*="__ripple"] {
  left: 0;
}

.input-row {
  max-height: 4.5rem;
}

.textField>>>.v-input__slot {
  border-radius: 12px !important;
}

.custom-checkbox>>>.v-icon {
  color: white !important;
  /* Bijela boja i kad nije označen */
}

.custom-checkbox.v-input--is-disabled>>>.v-icon {
  color: black !important;
  /* Tamna boja kad je disabled */
}
</style>
